
// 指定截取后面number位
const behindStr = (value, number) => {
  if (value) {
    return '...' + value.substr(value.length - number, value.length - 1)
  } else {
    return '无'
  }
}

// 指定截取前后number位
const middleStr = (value, number) => {
  if (value) {
    return (
      value.substr(0, number) +
      '......' +
      value.substr(value.length - number, value.length - 1)
    )
  } else {
    return '无'
  }
}

export default {
  behindStr,
  middleStr
}
